/** @jsx jsx */
import { jsx } from '@emotion/core';

const svg = {
	bottom: -72,
	height: 300,
	left: 0,
	position: 'absolute',
};

const path = {
	fill: 'none',
};

export default function TrebleClef() {
	return (
		<svg css={svg} version="1.1" x="0px" y="0px" viewBox="0 0 90 224.3">
			<path css={path} d="M51.4,8.3C51.4,8.3,51.4,8.3,51.4,8.3C51.4,8.3,51.4,8.3,51.4,8.3z" />
			<path css={path} d="M51.3,7.8C51.2,7.8,51.3,7.8,51.3,7.8L51.3,7.8z" />
			<path css={path} d="M56.9,51.6c0.8-1.9,1.3-4,1.7-6.1c0.2-1,0.4-2.2,0.5-3.2l0.2-1.8l0.1-1.6c0.3-4.5,0.4-9.1-0.1-13.5
		c-0.5-4.4-1.6-8.8-3.6-12.5c-1-1.8-2.3-3.4-3.5-4.1c-0.3-0.2-0.5-0.3-0.7-0.4c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.2,0,0.1,0l-0.2,0
		c-0.1,0.1-0.4,0.3-0.8,0.7c-1,1.2-1.9,3.3-2.5,5.4c-0.7,2.1-1.1,4.4-1.6,6.7c-0.8,4.6-1.3,9.3-1.5,14c-0.3,4.7-0.4,9.5-0.4,14.2
		c0,6.6,0.2,13.2,0.5,19.7c1.8-2.1,3.6-4.2,5.3-6.3C53.1,59.1,55.4,55.5,56.9,51.6z" />
			<path css={path} d="M50.6,154.9c-1.3-7.8-2.7-16-4-24.3c-0.6-3.9-1.2-7.8-1.7-11.8c-0.1,0-0.1,0-0.2,0.1c-3.8,1.5-6.9,4-9.1,6.8
		c-2.2,2.8-3.6,5.9-4.2,8.8c-0.7,2.9-0.6,5.5-0.2,7.5c0.4,2.1,1.3,3.6,1.9,4.5c0.3,0.5,0.7,0.8,0.9,1c0.2,0.2,0.3,0.3,0.3,0.3
		s-0.1-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-1.1-0.6-2.8-1.8-4.2-4c-1.4-2.1-2.6-5.3-2.9-9.1
		c-0.3-3.8,0.3-8.3,2.6-12.9c2.2-4.6,6.2-9.1,11.6-12.3c0.7-0.4,1.4-0.7,2.1-1.1l1.1-0.5c0.2-0.1,0.3-0.1,0.4-0.2
		c-0.6-4.7-1.1-9.6-1.5-14.4c-0.2,0.2-0.4,0.4-0.7,0.6c-1.8,1.7-3.5,3.3-5.3,4.9c-1.6,1.5-3.2,3.1-4.7,4.7c-3,3.3-5.8,6.7-8.2,10.3
		c-4.8,7.2-8.2,15.3-8.9,23.6c-0.1,1-0.1,2.1-0.1,3.1c0.1,1,0.1,2.1,0.2,3.1c0.2,2,0.6,4,1.3,6c0.6,1.9,1.5,3.7,2.6,5.5
		c1,1.7,2.4,3.3,3.8,4.7c1.4,1.4,3.1,2.6,4.8,3.7l1.4,0.8l0.6,0.4l0.7,0.3l1.4,0.7l1.4,0.6c1.9,0.7,3.9,1.3,5.9,1.7
		c2,0.4,4,0.6,6,0.7c2.8,0.1,5.7-0.2,8.3-0.8c0-0.2-0.1-0.4-0.1-0.7C51.9,162.7,51.3,158.8,50.6,154.9z" />
			<path css={path} d="M73.9,141.6l0-0.1l0-0.3l0-0.5c-0.1-0.7-0.1-1.4-0.3-2.2c-0.2-1.4-0.6-2.8-1.1-4.1c-1-2.7-2.4-5.1-4-7.3
		c-3.3-4.3-7.5-7.4-11.7-8.8c-2.1-0.7-4.1-1-6.1-0.9c-0.5,0-1,0.1-1.5,0.1c0,0,0,0-0.1,0c0.4,4.2,0.9,8.4,1.4,12.5
		c1.5,12.7,3.1,24.9,4.7,36.4c3.9-1.3,7.5-3.3,10.4-5.9c5.5-4.8,8.3-11.6,8.4-17.5L73.9,141.6C74,141.5,73.9,141.8,73.9,141.6z" />
			<path css={{fill: '#322E40'}} d="M83.4,131.3c-0.5-2.1-1.1-4.1-1.9-5.9c-0.8-1.9-1.8-3.7-2.9-5.4c-4.4-6.7-10.9-11.7-18.3-13.7
		c-1.8-0.5-3.7-0.8-5.6-1c-1.9-0.1-3.7-0.1-5.5,0.2c-0.5,0.1-1,0.1-1.4,0.2c-0.5-5.8-1-11.6-1.5-17.5c5.3-5.4,10.4-11.1,15-17.8
		c3-4.4,5.7-9.4,7.3-14.8c0.8-2.7,1.4-5.5,1.8-8.2c0.2-1.4,0.3-2.7,0.4-4.1l0.1-1.8l0-2c0-5.3-0.5-10.6-1.8-15.8
		c-1.3-5.2-3.2-10.3-6.5-15c-0.8-1.2-1.7-2.3-2.8-3.4C58.8,4.1,57.5,3,56,2.2c-0.8-0.4-1.7-0.8-2.7-1c-0.5-0.1-1-0.2-1.6-0.2
		c-0.2,0-0.1,0-0.5,0L51,1c-0.2,0-0.8,0.1-1,0.1c-1.2,0.3-2.3,0.8-3.1,1.5c-0.8,0.6-1.4,1.3-1.9,2c-1.9,2.7-2.7,5.3-3.4,7.9
		c-0.7,2.6-1.1,5.1-1.4,7.6c-1.2,10-1.2,19.7-1,29.2c0.2,8.4,0.6,16.7,1.2,24.9c-2.9,3.1-5.9,6.1-8.8,9.1c-1.7,1.7-3.4,3.4-5.1,5.2
		c-1.8,1.9-3.5,3.8-5.1,5.8c-3.3,4-6.1,8.4-8.5,12.9c-2.4,4.6-4.3,9.4-5.5,14.4c-1.2,5-1.8,10.1-1.4,15.2c0,1.3,0.2,2.5,0.4,3.8
		c0.2,1.3,0.4,2.5,0.8,3.7c0.6,2.5,1.5,4.8,2.5,7.1c2.1,4.6,5.3,8.5,9.1,11.5l1.4,1.1l1.5,1c1,0.7,2.1,1.3,3.1,1.8l1.5,0.7l0.8,0.4
		l0.8,0.3l1.6,0.6l1.6,0.6c4.3,1.5,8.8,2.3,13.2,2.4c3.1,0.1,6.2-0.1,9.2-0.7c0.3,2.1,0.6,4.1,0.9,6.2c0.5,3.6,0.9,7.2,1.3,10.7
		c0.3,3.5,0.5,6.9,0.4,10.2c-0.1,1.6-0.2,3.3-0.4,4.8c-0.1,0.4-0.1,0.8-0.2,1.1l-0.2,1.2c-0.2,0.7-0.4,1.4-0.7,2.1
		c-1,2.8-2.7,5.3-4.7,7.2c-2,1.9-4.4,3.2-6.9,3.8l-0.9,0.2l-0.2,0.1c0,0-0.1,0-0.1,0l-0.1,0l-0.5,0.1c-0.6,0.1-1.2,0.1-1.8,0.1
		c-1.2,0-2.4-0.1-3.5-0.3c-1.1-0.2-2.2-0.6-3.2-1.1c-1-0.5-1.8-1.1-2.6-1.7c-1.5-1.3-2.5-2.9-2.9-4.5c-0.3-0.8-0.4-1.6-0.4-2.4
		c0-0.4,0-0.8,0-1.2l0.1-0.6c0,0.2,0,0,0,0l0-0.1l0-0.2l0-0.4c0.1-0.5,0.2-1.3,0.5-1.9c0.2-0.6,0.5-1.2,0.7-1.7
		c0.4-0.6,0.8-1.1,1.2-1.5c1.6,1.1,3.5,1.8,5.5,1.8c5.2,0,9.4-4.2,9.4-9.4c0-5.2-4.1-9.6-9.3-9.7c-3.7-0.1-7.5,1-11,4.7
		c-2.8,2.9-5,7.1-5.6,11.6c-0.2,1.1-0.2,2.3-0.2,3.4c0,1.2,0.1,2.3,0.4,3.6c0.5,2.2,1.4,4.7,2.9,6.9c1.5,2.2,3.5,4.2,5.9,5.6
		c1.2,0.7,2.5,1.3,3.8,1.7c1.3,0.5,2.7,0.8,4.2,0.9c1.4,0.2,2.9,0.2,4.4,0c0.7-0.1,1.5-0.2,2.2-0.3l0.5-0.1l0.3-0.1l0.3-0.1l1.1-0.3
		c1.5-0.5,2.9-1.1,4.2-2c1.3-0.8,2.6-1.8,3.7-3c2.2-2.3,4-5.2,5-8.4c0.3-0.8,0.5-1.7,0.7-2.5l0.2-1.2c0.1-0.5,0.1-0.9,0.2-1.3
		c0.2-1.7,0.3-3.4,0.3-5.1c0.1-6.9-0.8-13.9-1.7-21.3c-0.3-2.1-0.6-4.2-0.8-6.4c4.8-1.1,9.3-3,13.4-5.7c3.6-2.4,6.8-5.5,9.3-9
		c1.3-1.8,2.3-3.7,3.2-5.7c0.4-1,0.8-2,1.1-3.1c0.2-0.5,0.3-1.1,0.5-1.7c0.1-0.6,0.3-1.2,0.4-1.6l0.3-1.4l0.2-1.8
		c0.1-1.1,0.2-2.2,0.1-3.3C84.2,135.5,83.9,133.3,83.4,131.3z M51.4,8.3C51.4,8.3,51.4,8.3,51.4,8.3C51.4,8.3,51.4,8.3,51.4,8.3z
		 M51.3,7.8L51.3,7.8C51.3,7.8,51.2,7.8,51.3,7.8z M44.6,49.3c0-4.7,0.1-9.4,0.4-14.2c0.3-4.7,0.7-9.4,1.5-14
		c0.4-2.3,0.9-4.6,1.6-6.7c0.6-2.1,1.5-4.2,2.5-5.4c0.3-0.4,0.6-0.6,0.8-0.7l0.2,0c-0.3,0,0,0-0.1,0c0.1,0,0.1,0,0.2,0.1
		c0.2,0.1,0.4,0.2,0.7,0.4c1.2,0.7,2.5,2.3,3.5,4.1c2,3.6,3.1,8,3.6,12.5c0.5,4.5,0.4,9,0.1,13.5l-0.1,1.6l-0.2,1.8
		c-0.2,1-0.3,2.2-0.5,3.2c-0.4,2.1-1,4.1-1.7,6.1c-1.5,3.9-3.8,7.5-6.5,11.1c-1.6,2.1-3.4,4.2-5.3,6.3
		C44.8,62.4,44.6,55.9,44.6,49.3z M44.3,167.9c-2-0.1-4-0.3-6-0.7c-2-0.4-4-1-5.9-1.7l-1.4-0.6l-1.4-0.7l-0.7-0.3l-0.6-0.4l-1.4-0.8
		c-1.6-1-3.3-2.2-4.8-3.7c-1.4-1.4-2.8-3-3.8-4.7c-1.1-1.7-2-3.5-2.6-5.5c-0.7-1.9-1.1-3.9-1.3-6c-0.2-1-0.1-2-0.2-3.1
		c0-1,0-2.1,0.1-3.1c0.6-8.3,4.1-16.4,8.9-23.6c2.4-3.6,5.2-7.1,8.2-10.3c1.5-1.6,3.1-3.2,4.7-4.7c1.7-1.6,3.5-3.3,5.3-4.9
		c0.2-0.2,0.4-0.4,0.7-0.6c0.5,4.9,1,9.7,1.5,14.4c-0.1,0.1-0.3,0.1-0.4,0.2l-1.1,0.5c-0.7,0.4-1.4,0.7-2.1,1.1
		c-5.5,3.1-9.5,7.7-11.6,12.3c-2.2,4.6-2.8,9.1-2.6,12.9c0.3,3.8,1.4,6.9,2.9,9.1c1.4,2.2,3,3.3,4.2,4c0.3,0.1,0.5,0.3,0.7,0.4
		c0.2,0.1,0.4,0.1,0.5,0.2c0.3,0.1,0.4,0.2,0.4,0.2s-0.1-0.1-0.3-0.3c-0.2-0.2-0.5-0.6-0.9-1c-0.7-0.9-1.5-2.5-1.9-4.5
		c-0.4-2.1-0.5-4.7,0.2-7.5c0.6-2.9,2-6,4.2-8.8c2.2-2.8,5.3-5.2,9.1-6.8c0.1,0,0.1,0,0.2-0.1c0.5,4,1.1,7.9,1.7,11.8
		c1.3,8.4,2.6,16.5,4,24.3c0.7,3.9,1.3,7.8,2,11.6c0,0.2,0.1,0.4,0.1,0.7C50,167.7,47.2,168,44.3,167.9z M73.9,143
		c-0.1,5.9-2.9,12.7-8.4,17.5c-2.9,2.6-6.5,4.6-10.4,5.9c-1.6-11.5-3.2-23.7-4.7-36.4c-0.5-4.1-0.9-8.2-1.4-12.5c0,0,0,0,0.1,0
		c0.5-0.1,1-0.1,1.5-0.1c2-0.1,4.1,0.2,6.1,0.9c4.1,1.4,8.4,4.5,11.7,8.8c1.6,2.2,3,4.6,4,7.3c0.5,1.3,0.9,2.7,1.1,4.1
		c0.2,0.7,0.2,1.4,0.3,2.2l0,0.5l0,0.3l0,0.1c0,0.2,0-0.1,0,0L73.9,143z" />
		</svg>
	)
}
