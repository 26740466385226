export const notes = [
	[[5, 0], [6, 5]],
	[[6, 0]],
	[[6, 1]],
	[[6, 2]],
	[[6, 3]],
	[[5, 2], [6, 7]],
	[[4, 0], [5, 5]],
	[[4, 2], [5, 7]],
	[[4, 3], [5, 8]],
	[[4, 4]],
	[[3, 0], [4, 5]],
	[[3, 2], [4, 7]],
	[[2, 0], [3, 4]],
	[[2, 1], [3, 5]],
	[[2, 3], [3, 7]],
	[[1, 0], [2, 5]],
	[[1, 1], [2, 6]],
	[[1, 2], [2, 7]],
	[[1, 3], [2, 8]],
	[[1, 4]],
	[[5, 3], [6, 8]],
	[[2, 2], [3, 6]],
	[[3, 1], [4, 6]],
	[[3, 1, 'f'], [4, 6, 'f']],
	[[3, 3, 's'], [4, 8]],
	[[1, 5]],
	[[1, 6]],
	[[1, 7]],
	[[1, 8]],
	[[6, 4]],
	[[2, 4, 's'], [3, 8]],
	[[4, 1], [5, 6]],
	[[3, 3, 'f'], [4, 8]],
];

export const noteNameMapping = {
	[[1, 0]]: 'E',
	[[1, 1]]: 'F',
	[[1, 2]]: 'F#',
	[[1, 3]]: 'G',
	[[1, 4]]: 'G#',
	[[1, 5]]: 'A',
	[[1, 6]]: 'B♭',
	[[1, 7]]: 'B',
	[[1, 8]]: 'C',
	[[2, 0]]: 'B',
	[[2, 1]]: 'C',
	[[2, 2]]: 'C#',
	[[2, 3]]: 'D',
	[[2, 4, 's']]: 'D#',
	[[3, 0]]: 'G',
	[[3, 1, 'f']]: 'A♭',
	[[3, 1]]: 'G#',
	[[3, 2]]: 'A',
	[[3, 3, 'f']]: 'B♭',
	[[3, 3, 's']]: 'A#',
	[[3, 6]]: 'C#',
	[[4, 0]]: 'D',
	[[4, 1]]: 'D#',
	[[4, 2]]: 'E',
	[[4, 3]]: 'F',
	[[4, 4]]: 'F#',
	[[4, 6]]: 'G#',
	[[5, 0]]: 'A',
	[[6, 5]]: 'A',
	[[5, 2]]: 'B',
	[[5, 3]]: 'C',
	[[5, 3]]: 'C',
	[[6, 0]]: 'E',
	[[6, 1]]: 'F',
	[[6, 2]]: 'F#',
	[[6, 3]]: 'G',
	[[6, 4]]: 'A♭',
};
